.house-living_room {
  position: fixed;
  width: 200px;
  height: 250px;
  top: 60%;
  right: -60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 2px solid white;
  border-radius: 16px;
  backdrop-filter: blur(2.5px);

  div {
    padding: 22px 0px;
    margin: 10px 0px;
    height: 40px;
    width: 80%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 99px;
    color: #01976a;
    font-family: "Lora";
    font-style: normal;
    // padding: 0 25px;
  }

  :hover {
    cursor: pointer;
    background: linear-gradient(97.43deg, #013e25 3.13%, #006c43 45.31%, #0aab81 75%, #017649 100%);
    font-family: "Lora";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: white;
    border: 1px solid #b0863a;
  }

  .active {
    background: linear-gradient(97.43deg, #013e25 3.13%, #006c43 45.31%, #0aab81 75%, #017649 100%);
    font-family: "Lora";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: white;
    border: 1px solid #b0863a;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
  .house-living_room {
    width: 130px;
    height: 180px;
    // right: -60px;
  
    div {
      padding: 0px;
      margin: 5px 0px;
      height: 40px;
      width: 70%;
      // padding: 0 25px;
    }
  }
}